import type Options from "../types/Options";
import useApi from "../services/api/hooks/useApi";
import ApiHttpVerbs from "../services/api/types/ApiHttpVerbs";
import {useParams} from "react-router-dom";
import Api from "../services/auth/types/IsaacApi";
import type ApiResponse from "../services/api/types/ApiResponse";

const useEditableHook = (): {
  getTypeOptionsFromMatRef: (
    module: string,
    property: string,
    filter: string,
  ) => Promise<Options | null>;
  validateConstructionPropertyChanged: (
    entity: string,
    property: string,
    constructionId?: string,
    entityId?: string,
    value?: string,
  ) => Promise<string | undefined>;
} => {
  const {id} = useParams();
  const {fetchData} = useApi();

  const getTypeOptionsFromMatRef = async (
    module: string,
    property: string,
    filter: string,
  ): Promise<Options | null> => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/materialReference/editable/${module}?property=${property}&filter=${filter}`;
    const response = await fetchData<Options>(url, Api.ICC);
    return response.data;
  };

  const validateConstructionPropertyChanged = async (
    entityName: string,
    propertyName: string,
    entityId?: string,
    propertyValue?: string,
  ): Promise<string | undefined> => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/constructions/${id}/validateProperty`;
    const response = await fetchData<ApiResponse<any>>(
      url,
      Api.ICC,
      {useGlobalHandler: false},
      ApiHttpVerbs.POST,
      {},
      JSON.stringify({entityName, entityId, propertyValue, propertyName}),
    );

    return response.success ? undefined : response.errors;
  };

  return {
    getTypeOptionsFromMatRef,
    validateConstructionPropertyChanged,
  };
};

export default useEditableHook;
