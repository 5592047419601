import type {SignalRMessage} from "../../../services/signalR/models/Messages";
import {toast} from "react-toastify";
import {v4 as uuidv4} from "uuid";

const useDefaultHandler = (): ((message: SignalRMessage) => void) => {
  const execute = (message: SignalRMessage): void => {
    toast(`${message.type} executed successfully!`, {
      toastId: uuidv4(),
    });
  };
  return execute;
};

export default useDefaultHandler;
