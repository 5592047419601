import React from "react";
import type ICalculationConstructionDetailsProps from "./ICalculationConstructionDetailsProps";
import styles from "./CalculationConstructionDetails.module.scss";
import CalculationConstructionField from "./field/CalculationConstructionField";

const CalculationConstructionDetails: React.FunctionComponent<
  ICalculationConstructionDetailsProps
> = ({input, selectedMastNumber, profileCalculationResults}) => {
  const selectedMast = input.masts?.find(x => x.number === selectedMastNumber);

  const {
    profile,
    foundationType,
    foundationAdditionalDepth,
    foundationMastLength,
  } =
    profileCalculationResults?.mastResults?.find(
      (mr: any) => mr.mastNo === selectedMastNumber,
    ) || {};

  return (
    <div className={`${styles.infoContainer} page-brake-after`}>
      <div className={styles.infoBox}>
        <CalculationConstructionField label="Mast profile" value={profile} />
        <CalculationConstructionField
          label="Mast length"
          value={selectedMast?.length}
        />
        <CalculationConstructionField
          label="Mast leveling"
          value={selectedMast?.leveling}
        />
        <CalculationConstructionField
          label="Mast Reverse Deflection"
          value={selectedMast?.reverseDeflection}
        />
        <CalculationConstructionField
          label="Drillholes Code"
          value={selectedMast?.drillholesCode}
          isEditable={true}
        />
      </div>
      <div className={styles.infoBox}>
        <CalculationConstructionField
          label="Foundation Type"
          value={foundationType}
        />
        <CalculationConstructionField
          label="Additional Depth of the Foundation"
          value={foundationAdditionalDepth}
        />
        <CalculationConstructionField
          label="Length of mast in the foundation"
          value={foundationMastLength}
        />
      </div>
      <div className={styles.infoBox}>
        <CalculationConstructionField
          label="Permitted deformation"
          value={input.permittedDeformation}
        />
      </div>
    </div>
  );
};

export default CalculationConstructionDetails;
