// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import React from "react";
import {Checkbox, Icon, TooltipHost} from "@fluentui/react";

import TableCell from "../cell/TableCell";
import EditableItem from "../../../_legacy/editable/EditableItem";
import IsaacToggleButton from "../../../isaacToggleButton/IsaacToggleButton";
import type ITableRowProps from "./ITableRowProps";
import {EDITABLE, type EditableItemType} from "../../../../types/EditableItem";

const TableRow: React.FunctionComponent<ITableRowProps> = ({
  data,
  value,
  id,
  className,
  name,
  constructionId,
  isAllowToTakeAction,
  parent,
  isAccepted,
  isDisabled,
  handleToggleSwitch,
}: ITableRowProps) => {
  const TOOLTIP_KEY = "toolTips";
  const IS_SELECTED = "isSelected";

  return (
    <>
      {isAccepted && name === "masts" && (
        <tr style={{position: "relative"}}>
          <td
            style={{
              position: "absolute",
              left: "-10px",
              top: "50%",
              transform: "translateY(50%)",
              padding: "0 3px",
            }}>
            <TooltipHost
              content="This mast has been accepted, hence its properties and related elements cannot be modified"
              closeDelay={500}>
              <Icon iconName="Info" style={{fontSize: "16px", color: "blue"}} />
            </TooltipHost>
          </td>
        </tr>
      )}
      <tr key={id} className={className}>
        {data.map((column: any, i) => {
          // Note: Make use of the TableCell component here, adjust it if required
          if (
            value === undefined ||
            // eslint-disable-next-line no-prototype-builtins, @typescript-eslint/no-unsafe-argument
            !value.hasOwnProperty(column) ||
            (typeof value[column] === "object" && value[column] !== null)
          ) {
            return <td key={i} />;
          }

          const isEditableProperty = Object.keys(value).includes(EDITABLE);
          // Note: Make use of the TableCell component here, adjust it if required
          if (
            typeof value[column] === "boolean" &&
            !isEditableProperty &&
            !(column === IS_SELECTED)
          ) {
            return (
              <td key={i} style={{display: "flex", justifyContent: "center"}}>
                <Checkbox disabled checked={value[column]} />
              </td>
            );
          }
          if (column === IS_SELECTED) {
            return (
              <td key={i} className="no-print">
                <IsaacToggleButton
                  onChange={handleToggleSwitch}
                  checked={
                    !isDisabled(value, parent ?? name ?? "", column as string)
                  }
                />
              </td>
            );
          }
          // Note: Make use of the TableCell component here, adjust it if required
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          if (isEditableProperty) {
            const editable = value[EDITABLE] as EditableItemType[];

            if (!editable) {
              // eslint-disable-next-line array-callback-return
              return;
            }

            const editableItem = editable.find(col => col.name === column);

            if (
              // eslint-disable-next-line @typescript-eslint/prefer-optional-chain, @typescript-eslint/strict-boolean-expressions, @typescript-eslint/dot-notation
              editableItem &&
              editableItem.type &&
              // eslint-disable-next-line @typescript-eslint/prefer-optional-chain, @typescript-eslint/strict-boolean-expressions, @typescript-eslint/dot-notation
              value["id"] !== null &&
              isAllowToTakeAction
            ) {
              return (
                <td key={i}>
                  <EditableItem
                    extraInfo={value}
                    parent={parent}
                    value={value[column]}
                    entity={name}
                    property={column}
                    type={editableItem.type}
                    constructionId={constructionId}
                    entityId={editableItem.id}
                    isDisabled={isDisabled(
                      value,
                      parent ?? name ?? "",
                      column as string,
                      isAccepted,
                    )}
                  />
                </td>
              );
            }
          }

          return (
            <TableCell
              key={i}
              isDisabled={isDisabled(
                value,
                parent ?? name ?? "",
                column as string,
                isAccepted,
              )}
              column={column}
              value={value[column]}
              toolTip={value[TOOLTIP_KEY] ? value[TOOLTIP_KEY][column] : ""}
            />
          );
        })}
      </tr>
    </>
  );
};

TableRow.displayName = "TableRow";
export default TableRow;
