import React from "react";
import {v4 as uuidv4} from "uuid";
import {type TFunction} from "i18next";
import {useTranslation} from "react-i18next";
import {
  Stack,
  TooltipHost,
  DirectionalHint,
  Icon,
  type ITooltipHostStyles,
} from "@fluentui/react";

import styles from "../sidePanel/ConstructionCalculationsPanel.module.scss";
import {HeadersTooltips} from "./HeaderToolTips";

// Note: Most of this file is logic ported from old FE. Should be refactored
const CalculationMastResults: React.FunctionComponent<{
  mastResults: any;
}> = ({mastResults}) => {
  const {t} = useTranslation(["constructionCalculations"]);
  const mastResultsAreAvailable =
    mastResults != null && mastResults?.loadCaseResults?.length > 0;

  return (
    <Stack grow={5} className={`${styles.results}`}>
      {mastResultsAreAvailable && (
        <div className={styles.mastBtn}>
          <table className={styles.resultTable}>
            <thead>
              <tr className={styles.headerCategories}>
                <th key="empty-0" className={styles.emptyHeader} />
                <th key="[kN]" colSpan={3}>
                  [kN]
                </th>
                <th key="[kNm]" colSpan={3}>
                  [kNm]
                </th>
                <th key="[MPa]" colSpan={2}>
                  [MPa]
                </th>
                <th key="[mm]" colSpan={2}>
                  [mm]
                </th>
                <th key="[rad]" colSpan={1}>
                  [rad]
                </th>
                <th key="empty-1" colSpan={5} />
              </tr>

              <tr className={styles.headers}>
                {renderHeaders(mastResults.loadCaseResults, t)}
              </tr>

              <tr className={styles.emptyRow}>
                <td />
              </tr>
            </thead>
            <tbody>{renderResults(mastResults.loadCaseResults, t)}</tbody>
          </table>
        </div>
      )}
    </Stack>
  );
};

const renderHeaders = (mast: any, t: TFunction<[string], undefined>): any => {
  const headers = [];

  for (let i = 0; i < mast[0].loadCaseResultValues.length; i++) {
    headers.push(
      <TooltipHost
        content={t(mast[0].loadCaseResultValues[i].abbreviation as string)}>
        {mast[0].loadCaseResultValues[i].abbreviation}
      </TooltipHost>,
    );
  }

  for (let i = 0; i < mast[0].mastValidationControls.length; i++) {
    if (mast[0].mastValidationControls[i].isVisible) {
      headers.push(mast[0].mastValidationControls[i].abbreviation);
    }
  }

  if (mast[0].foundationValidationControls?.isFoundationValid !== null) {
    headers.push("Foundation OK");
  }

  if (mast[0].footPlateValidationControls !== null) {
    headers.push("FootPlate OK");
  }

  // eslint-disable-next-line react/jsx-key
  const results = [<th key="def" />];

  const hostStyles: Partial<ITooltipHostStyles> = {root: {display: "grid"}};
  const calloutProps = {gapSpace: 0};

  for (let i = 0; i < headers.length; i++) {
    if (
      (headers[i] === "DBL" ||
        headers[i] === "DML" ||
        headers[i] === "BT" ||
        headers[i] === "zt" ||
        headers[i] === "ft") &&
      HeadersTooltips[headers[i] as string]
    ) {
      results.push(
        <th key={i} style={{cursor: "pointer"}}>
          <TooltipHost
            content={HeadersTooltips[headers[i]]}
            id={styles.tooltipRes}
            calloutProps={calloutProps}
            styles={hostStyles}
            directionalHint={DirectionalHint.bottomCenter}>
            <div>{headers[i]}</div>
          </TooltipHost>
        </th>,
      );
    } else {
      results.push(
        <th key={i} style={{cursor: "pointer"}}>
          {headers[i]}
        </th>,
      );
    }
  }

  return results;
};

const renderResults = (
  mast: any,
  t: TFunction<"translation", undefined>,
): any => {
  const result = [];

  const hostStyles: Partial<ITooltipHostStyles> = {root: {display: "grid"}};
  const calloutProps = {gapSpace: 0};

  for (let i = 0; i < mast.length; i++) {
    result.push(
      <tr key={i} className={styles.result}>
        <td>
          <TooltipHost
            content={
              <div>
                <p>
                  {`Additional information for ${mast[i].loadCase.slsUls}${mast[i].loadCase.wind.type} `}
                  <Icon
                    iconName={getIconByWindDirection(
                      mast[i].loadCase.wind.direction as string,
                    )}
                  />
                  {` to show here.`}
                </p>
              </div>
            }
            id={styles.tooltip}
            calloutProps={calloutProps}
            styles={hostStyles}
            directionalHint={DirectionalHint.rightCenter}>
            <div className={styles.loadCaseName}>
              <p>
                {t(
                  `${mast[i].loadCase.slsUls + mast[i].loadCase.wind.type + mast[i].loadCase.wind.direction}`,
                )}
              </p>
              <Icon
                iconName={getIconByWindDirection(
                  mast[i].loadCase.wind.direction as string,
                )}
                className={styles.loadCaseIcon}
              />
            </div>
          </TooltipHost>
        </td>

        {renderLoadCase(
          mast[i].loadCaseResultValues,
          mast[i].mastValidationControls,
          mast[i].foundationValidationControls,
          mast[i].footPlateValidationControls,
        )}
      </tr>,
    );
  }

  return result;
};

const getIconByWindDirection = (windDirection: string): string => {
  const windDirectionIcons: Record<string, string> = {
    "-": "Cancel",
    ">": "Forward",
    "<": "Back",
    "^": "Up",
    v: "Down",
  };
  return windDirectionIcons[windDirection];
};

const renderLoadCase = (
  loadCaseResultValues: any,
  mastValidationControls: any,
  foundationValidationControls: any,
  footPlateValidationControls: any,
): any => {
  const results = [];

  for (let i = 0; i < loadCaseResultValues.length; i++) {
    const maxValue = loadCaseResultValues[i].isMaxValue
      ? styles.isMaxValue
      : "";
    if (loadCaseResultValues[i].hasValidationControl) {
      const className = loadCaseResultValues[i].isValid
        ? styles.greenBox
        : styles.redBox;

      results.push(
        <td
          key={`${i}-${loadCaseResultValues[i].value}`}
          className={`${className} ${maxValue}`}
          title={loadCaseResultValues[i].value}>
          {(
            Math.round(
              (loadCaseResultValues[i].value + Number.EPSILON) * 1000,
            ) / 1000
          )?.toFixed(3)}
        </td>,
      );
    } else {
      results.push(
        <td
          className={maxValue}
          key={`${i}-${loadCaseResultValues[i].value}`}
          title={loadCaseResultValues[i].value}>
          {(
            Math.round(
              (loadCaseResultValues[i].value + Number.EPSILON) * 1000,
            ) / 1000
          )?.toFixed(3)}
        </td>,
      );
    }
  }

  for (let i = 0; i < mastValidationControls.length; i++) {
    addCheckMarkCellToRow(
      results,
      i,
      mastValidationControls[i].value,
      mastValidationControls[i].isVisible as boolean,
    );
  }

  addCheckMarkCellToRow(
    results,
    0,
    foundationValidationControls?.isFoundationValid,
    foundationValidationControls?.isFoundationValid !== null,
  );

  addCheckMarkCellToRow(
    results,
    0,
    footPlateValidationControls,
    footPlateValidationControls as boolean,
  );

  return results;
};

/** Temporary code to generate add 'CheckMark' table cells to rows. Should be removed after refactoring to use IsaacTable. */
const addCheckMarkCellToRow = (
  result: any[],
  cellNumber: number | any,
  isValid: boolean | any,
  shouldAddCell: boolean,
): void => {
  if (shouldAddCell) {
    result.push(generateCheckMarkCell(cellNumber, isValid));
  }
};

/** Temporary code to generate 'CheckMark' table cells. Should be removed after refactoring to use IsaacTable. */
const generateCheckMarkCell = (
  cellNumber: number | any,
  isValid: boolean | any,
): JSX.Element => {
  const className = isValid ? styles.greenBox : styles.redBox;
  const value = isValid ? (
    <Icon iconName="CheckMark" />
  ) : (
    <Icon iconName="Error" />
  );

  return (
    <td key={`${uuidv4()}-${cellNumber}`} className={className}>
      {value}
    </td>
  );
};

export default CalculationMastResults;
