import React from "react";
import {useDispatch, useSelector} from "react-redux";

import styles from "./Table.module.scss";
import TranslatedText from "../translatedText/TranslatedText";
import TableRow from "./row/TableRow";
import type ITableProps from "./ITableProps";
import type {RootState} from "../../../store";
import type {SelectedEntity} from "../../../types/SelectedEntity";
import {updateSelectedEntities} from "../../../pages/construction/constructionSlice";

const Table: React.FunctionComponent<ITableProps> = ({
  columns,
  values,
  name,
  parent,
  count,
  tableId,
  constructionId,
  isAllowToTakeAction,
}: ITableProps) => {
  const columnsWithoutId = columns.filter(
    c => c !== "id" && c !== "selectionId",
  );

  const dispatch = useDispatch();

  // Note: State should not exist at this level
  const {data, connections, deselectedEntities} = useSelector(
    (state: RootState) => state.construction,
  );

  const handleToggleSwitch = (value: any, checked: boolean): void => {
    if (isAllowToTakeAction) {
      const entity: SelectedEntity = {
        entityId: value.selectionId,
        entityType: parent ?? name ?? "",
        compartment: value.compartment,
        position: value.position,
        checked,
      };
      dispatch(updateSelectedEntities(entity));
    }
  };

  const isDeselected = (value: any, entityType: string): boolean =>
    deselectedEntities?.some(
      d => d.entityType === entityType && d.entityId === value?.selectionId,
    ) ?? false;

  const isDisabled = (
    value: any,
    entityType: string,
    columnName: string,
    isAccepted: boolean,
  ): boolean => {
    if (isAccepted) return true;

    if (entityType === "devices" && columnName === "fixationHeight") {
      const deviceParentId: string | undefined = connections?.find(
        c => c.entityType === entityType && c.entityId === value?.id,
      )?.parentId;

      return (
        (data?.beams?.some(b => b.id === deviceParentId) ||
          isDeselected(value, entityType)) ??
        false
      );
    }

    return isDeselected(value, entityType);
  };

  return (
    <div id={tableId} className={styles.constructionDataItem}>
      {(values.length > 0 || count === null) && (
        <div>
          <div className={styles.resultTableContainer}>
            <table className={styles.resultTable}>
              <thead>
                <tr className={styles.headers}>
                  {columnsWithoutId.map((column, i) => (
                    <th key={i}>
                      <TranslatedText value={column} parent={name ?? parent} />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {values.length > 0 &&
                  values.map((value, i) => (
                    <TableRow
                      key={i}
                      id={i}
                      data={columnsWithoutId}
                      className={styles.result}
                      value={value}
                      name={name}
                      parent={parent}
                      constructionId={constructionId}
                      isAllowToTakeAction={isAllowToTakeAction}
                      isAccepted={value.isAccepted}
                      handleToggleSwitch={(checked: boolean) => {
                        handleToggleSwitch(value, checked);
                      }}
                      isDisabled={isDisabled}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

Table.displayName = "Table";

export default Table;
