import {createSlice} from "@reduxjs/toolkit";
import {type PayloadAction} from "@reduxjs/toolkit";

import type ICalculation from "../../types/ICalculation";
import type CalculationStatus from "../../types/CalculationStatus";
import type ICalculationConstructionDetails from "../../types/ICalculationConstructionDetails";
import type MaxLoadCaseValue from "./types/MaxLoadCaseValue";

export interface ConstructionCalculationsState {
  constructionDetails: ICalculationConstructionDetails | undefined;
  calculations: ICalculation[];
  selectedCalculationId: string | undefined;
  selectedMastNo: string | undefined;
  selectedCalculationResults: any;
}

const initialState: ConstructionCalculationsState = {
  constructionDetails: undefined,
  selectedCalculationId: undefined,
  selectedMastNo: undefined,
  selectedCalculationResults: undefined,
  calculations: [],
};

export const constructionCalculationsSlice = createSlice({
  name: "constructionCalculations",
  initialState,
  reducers: {
    load: (
      state,
      {
        payload,
      }: PayloadAction<{
        currentCalculationId: string | undefined;
        selectedProfileCalculationId: string | undefined;
        calculations: ICalculation[];
        constructionDetails: any | undefined;
        currentProfileCalculationResults: any | undefined;
        currentMastNo: string | undefined;
      }>,
    ) => {
      state.calculations = payload.calculations;
      state.constructionDetails = payload.constructionDetails;
      state.selectedCalculationId = payload.currentCalculationId;
      state.selectedMastNo = payload.currentMastNo;
      state.selectedCalculationResults = markGratesLoadCaseValue(
        payload.currentProfileCalculationResults,
      );
    },
    switchMast: (state, {payload}: PayloadAction<string>) => {
      state.selectedMastNo = payload;
    },
    updateStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        calculationId: string;
        status: CalculationStatus;
      }>,
    ) => {
      const index = state.calculations.findIndex(
        c => c.calculationId === payload.calculationId,
      );

      if (index !== -1) {
        state.calculations[index].status = payload.status;
      }
    },
    updateData: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        results: any;
      }>,
    ) => {
      state.selectedCalculationResults = markGratesLoadCaseValue(
        payload.results,
      );

      const calculationIndex = state.calculations.findIndex(
        c => c.calculationId === payload.id,
      );
      const prevSelectedIndex = state.calculations.findIndex(c => c.selected);

      state.calculations[prevSelectedIndex].selected = false;
      state.selectedCalculationId =
        state.calculations[calculationIndex]?.calculationId;

      if (
        calculationIndex !== undefined &&
        state.calculations[calculationIndex] !== undefined
      ) {
        state.calculations[calculationIndex].selected = true;
      }
    },
    removeById: (state, {payload}: PayloadAction<string>) => {
      state.calculations = state.calculations.filter(
        c => c.calculationId !== payload,
      );
    },
    clear: state => {
      state.calculations = [];
      state.selectedCalculationId = undefined;
      state.selectedMastNo = undefined;
      state.selectedCalculationResults = undefined;
      state.constructionDetails = undefined;
    },
  },
});

const getMaxValues = (
  lclCurrentProfileCalculationResults: any,
): Record<string, MaxLoadCaseValue> => {
  const maxValuesPerMast: Record<string, MaxLoadCaseValue> = {};

  lclCurrentProfileCalculationResults?.mastResults?.forEach((mr: any) =>
    mr.loadCaseResults.forEach((lc: any) =>
      lc.loadCaseResultValues.forEach((lcr: any) => {
        if (!maxValuesPerMast[mr.mastNo]) {
          maxValuesPerMast[mr.mastNo] = {
            sk: 0,
            ft: 0,
            zt: 0,
            ut: 0,
            st: 0,
          };
        }

        const shouldComparisonBeSkipped = shouldSlsOrUlsValueBeSkipped(
          lcr.abbreviation as string,
          lc.loadCase.slsUls as string,
        );

        if (
          !shouldComparisonBeSkipped &&
          Math.abs(maxValuesPerMast[mr.mastNo][lcr.abbreviation]) <
            Math.abs(Number(lcr.value))
        ) {
          maxValuesPerMast[mr.mastNo][lcr.abbreviation] = lcr.value;
        }
      }),
    ),
  );

  return maxValuesPerMast;
};

const shouldSlsOrUlsValueBeSkipped = (
  abbreviation: string,
  slsOrUls: string,
): boolean => {
  let shouldValueBeSkipped = false;

  if (
    slsOrUls === "ULS" &&
    (abbreviation === "zt" || abbreviation === "ut" || abbreviation === "ft")
  ) {
    shouldValueBeSkipped = true;
  }

  if (slsOrUls === "SLS" && (abbreviation === "sk" || abbreviation === "st")) {
    shouldValueBeSkipped = true;
  }

  return shouldValueBeSkipped;
};

const markGratesLoadCaseValue = (
  lclCurrentProfileCalculationResults: any,
): any => {
  const maxValuesPerMast = getMaxValues(lclCurrentProfileCalculationResults);

  return {
    ...lclCurrentProfileCalculationResults,
    mastResults: lclCurrentProfileCalculationResults?.mastResults?.map(
      (mr: any) => {
        return {
          ...mr,
          loadCaseResults: mr.loadCaseResults?.map((lc: any) => ({
            ...lc,
            loadCaseResultValues: lc.loadCaseResultValues.map((lcr: any) => {
              if (!maxValuesPerMast[mr.mastNo]) {
                maxValuesPerMast[mr.mastNo] = {
                  sk: 0,
                  ft: 0,
                  zt: 0,
                  ut: 0,
                  st: 0,
                };
              }

              const shouldValueNotBeBolded = shouldSlsOrUlsValueBeSkipped(
                lcr.abbreviation as string,
                lc.loadCase.slsUls as string,
              );

              if (
                !shouldValueNotBeBolded &&
                maxValuesPerMast[mr.mastNo][lcr.abbreviation] === lcr.value
              ) {
                return {...lcr, isMaxValue: true};
              }

              return lcr;
            }),
          })),
        };
      },
    ),
  };
};

export const {load, updateStatus, switchMast, updateData, removeById, clear} =
  constructionCalculationsSlice.actions;

export default constructionCalculationsSlice.reducer;
