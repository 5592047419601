import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {type ICommandBarItemProps, PrimaryButton} from "@fluentui/react";
import {joinWithoutDuplicates} from "../../../utils/StringUtils";

import type IConstruction from "../../construction/types/IConstruction";
import type ApiResponse from "../../../services/api/types/ApiResponse";
import type AcceptedMastState from "../../../types/AcceptedMastState";
import ConstructionStatus from "../../../types/ConstructionStatus";
import useConstructionHook from "../../../hooks/useConstructionHook";
import CalculationStatus from "../../../types/CalculationStatus";

const useConstructionCalculationAcceptAndSaveHook = (
  construction: IConstruction | undefined,
  selectedCalculationResults: any | undefined,
  commandBarItems: ICommandBarItemProps[],
  isAllowedToTakeAction: boolean,
): {
  handleAccept: (() => Promise<void>) | undefined;
  handleDismiss: () => void;
  handleOpen: () => Promise<void>;
  open: boolean;
} => {
  const [open, setOpen] = useState<boolean>(false);
  const [constructionIds, setConstructionIds] = useState<string[]>([]);
  const [mastIdsPerMast, setMastIdsPerMast] = useState<
    Record<string, string[]>
  >({});

  const {t} = useTranslation(["constructionCalculations"]);
  const {getAcceptedMast, accept} = useConstructionHook();

  const handleDismiss = (): void => setOpen(prevState => !prevState);

  const handleOpen = async (): Promise<void> => {
    if (construction === undefined) return;

    const {masts} = construction;
    const {mastResults} = selectedCalculationResults;
    const promises: Array<Promise<ApiResponse<AcceptedMastState>>> = [];

    masts.forEach(mast => {
      const {mastCoordinates} = mast;
      const xCoordinate = Math.floor(Number(mastCoordinates["x-coordinate"]));
      const yCoordinate = Math.floor(Number(mastCoordinates["y-coordinate"]));
      const id = `${mast.no.replace("/", "-")}:${xCoordinate}:${yCoordinate}`;

      promises.push(getAcceptedMast(id));
    });

    const responses = await Promise.all(promises);
    const firstPreviouslyAcceptedMast =
      responses.find(r => r.data?.isAccepted)?.data ?? undefined;
    const matchingMastResult = mastResults.find((m: any) =>
      firstPreviouslyAcceptedMast?.mastIds.includes(m.mastId as string),
    );

    if (matchingMastResult !== undefined && !matchingMastResult.isAccepted) {
      toast(
        `Mast ${matchingMastResult.mastNo} has been accepted in the meantime, please recalculate the construction!`,
        {type: "warning"},
      );
      return;
    }

    responses.forEach(r => {
      const {data} = r;
      if (data !== null) {
        setConstructionIds(prevState =>
          joinWithoutDuplicates(
            prevState,
            mastResults.every((mr: any) => mr.isAccepted)
              ? [construction?.id]
              : data.constructionIds,
          ),
        );

        if (!mastIdsPerMast[data.number]) {
          setMastIdsPerMast(prevState => ({
            ...prevState,
            [data.number]: data.mastIds,
          }));
        }
      }
    });

    setOpen(prevState => !prevState);
  };

  const handleAccept = async (): Promise<void> => {
    if (construction === undefined) return;

    const {
      editableConstructionProperties,
      deselectedConstructionElements,
      calculationId,
      endedOn,
    } = selectedCalculationResults;

    await accept(
      construction.id,
      calculationId as string,
      endedOn as string,
      constructionIds.reverse(),
      mastIdsPerMast,
      {
        deselectedElements: deselectedConstructionElements,
        editableProperties: editableConstructionProperties,
      },
    );
  };

  const canBeAccepted =
    isAllowedToTakeAction &&
    selectedCalculationResults?.status === CalculationStatus.Completed &&
    construction?.status === ConstructionStatus.Registered;

  if (canBeAccepted) {
    commandBarItems.push({
      key: "acceptAndModify",
      onRender: () => (
        <PrimaryButton
          aria-label="Accept and save construction"
          text={t(`btnAcceptAndSaveConstruction`)}
          iconProps={{iconName: "Accept"}}
          onClick={() => {
            void handleOpen();
          }}
        />
      ),
    });
  }

  return {
    open,
    handleDismiss,
    handleOpen,
    handleAccept: canBeAccepted ? handleAccept : undefined,
  };
};

export default useConstructionCalculationAcceptAndSaveHook;
