import useConstructionHook from "../../../hooks/useConstructionHook";
import type {ModifiedConstructionElements} from "../../../types/IModifiedConstructionElements";

const useSaveModifiedConstructionHook = (): ((
  constructionId: string | undefined,
  calculationId: string,
  timeStamp: string,
  modifiedConstructionElements: ModifiedConstructionElements,
) => Promise<void>) => {
  const {saveModified} = useConstructionHook();

  const save = async (
    id: string | undefined,
    calculationId: string,
    timeStamp: string,
    modifiedConstructionElements: ModifiedConstructionElements,
  ): Promise<void> => {
    if (id === undefined) {
      throw new Error("Missing id");
    }

    await saveModified(
      id,
      calculationId,
      timeStamp,
      modifiedConstructionElements,
    );
  };

  return save;
};

export default useSaveModifiedConstructionHook;
