import {saveAs} from "file-saver";

import useApi from "../services/api/hooks/useApi";
import ApiHttpVerbs from "../services/api/types/ApiHttpVerbs";
import Api from "../services/auth/types/IsaacApi";
import type ApiResponse from "../services/api/types/ApiResponse";
import type ICalculation from "../types/ICalculation";
import type ICalculationHook from "../types/ICalculationHook";
import type IGetCalculationConstructionDetailsResponse from "../types/IGetCalculationConstructionDetailsResponse";
import type CalculationNoteMatGroundType from "../types/CalculationNoteMatGroundType";
import type {ModifiedConstructionElements} from "../types/IModifiedConstructionElements";

/** Hook that defines all BE calls for the Calculation entity. */
export const useCalculationHook = (): ICalculationHook => {
  const {fetchData} = useApi();

  const deleteCalculationByConstructionId = async (
    constructionId: string,
    correlationGuid: string,
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/calculations/byconstruction/${constructionId}`;
    const response = await fetchData<any>(
      url,
      Api.ISAAC,
      {useGlobalHandler: false},
      ApiHttpVerbs.DELETE,
      {
        "X-Correlation-ID": correlationGuid,
      },
    );
    return response;
  };

  const getCalculationsByConstructionId = async (
    id: string,
  ): Promise<ApiResponse<ICalculation[]>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/calculations/byconstruction/${id}`;
    const response = await fetchData<ICalculation[]>(url, Api.ISAAC);
    return response;
  };

  const getCalculationProfileResults = async (
    calculationId: string,
    profileCalculationId: string,
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/calculations/${calculationId}/profileCalculations/${profileCalculationId}/result`;
    const response = await fetchData<any>(url, Api.ISAAC);
    return response;
  };

  const stopCalculationById = async (
    calculationId: string,
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/calculations/${calculationId}/interrupt`;
    const response = await fetchData<any>(
      url,
      Api.ISAAC,
      {useGlobalHandler: false},
      ApiHttpVerbs.POST,
    );
    return response;
  };

  const getAllCalculations = async (): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/calculations`;
    const response = await fetchData<any>(url, Api.ISAAC);
    return response;
  };

  const getConstructionDetails = async (
    calculationId: string,
  ): Promise<ApiResponse<IGetCalculationConstructionDetailsResponse>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/calculations/${calculationId}/constructiondetails`;
    return await fetchData<IGetCalculationConstructionDetailsResponse>(
      url,
      Api.ISAAC,
    );
  };

  const createStartCalculation = async (
    constructionId: string,
    correlationGuid: string,
    collection?: ModifiedConstructionElements | null,
  ): Promise<ApiResponse<string>> => {
    const url = `${process.env.REACT_APP_ICC_API_URL}/constructions/${constructionId}/startCalculationWithEditableConstruction`;

    const response = await fetchData<string>(
      url,
      Api.ICC,
      {useGlobalHandler: false},
      ApiHttpVerbs.POST,
      {
        "X-Correlation-ID": correlationGuid,
      },
      JSON.stringify(collection),
    );
    return response;
  };

  const deleteCalculationById = async (
    id: string,
    correlationId: string,
  ): Promise<ApiResponse<void>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/calculations/${id}`;

    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    const response = await fetchData<any>(
      url,
      Api.ISAAC,
      {useGlobalHandler: false},
      ApiHttpVerbs.DELETE,
      {
        "X-Correlation-ID": correlationId,
      },
    );

    return response;
  };

  const downloadExcelInputMatrixById = async (
    calculationId: string,
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/calculations/${calculationId}/inputMatrix`;

    return await getDataAndSaveToFile(url);
  };

  const downloadJsonInputMatrixById = async (
    calculationId: string,
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/calculations/${calculationId}/inputMatrixJson`;

    return await getDataAndSaveToFile(url);
  };

  const downloadJsonCalculationConstructionById = async (
    calculationId: string,
  ): Promise<ApiResponse<any>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/calculations/${calculationId}/constructionJson`;

    return await getDataAndSaveToFile(url);
  };

  const getDataAndSaveToFile = async (
    url: string,
  ): Promise<ApiResponse<any>> => {
    const response = await fetchData<any>(url, Api.ISAAC, {
      useGlobalHandler: false,
    });

    if (
      response.success &&
      response.data !== null &&
      response.data.blob !== null
    ) {
      saveAs(response.data.blob as Blob, response.data.fileName as string);
    }

    return response;
  };

  const getCalculationNoteMatGroundTypesById = async (
    calculationId: string,
  ): Promise<ApiResponse<CalculationNoteMatGroundType[]>> => {
    const url = `${process.env.REACT_APP_ISAAC_API_URL}/calculations/${calculationId}/noteMatGroundTypes`;

    const response = await fetchData<CalculationNoteMatGroundType[]>(
      url,
      Api.ISAAC,
      {useGlobalHandler: false},
    );

    return response;
  };

  return {
    createStartCalculation,
    deleteCalculationByConstructionId,
    getCalculationsByConstructionId,
    getCalculationProfileResults,
    stopCalculationById,
    getAllCalculations,
    getConstructionDetails,
    deleteCalculationById,
    downloadExcelInputMatrixById,
    downloadJsonInputMatrixById,
    downloadJsonCalculationConstructionById,
    getCalculationNoteMatGroundTypesById,
  };
};

export default useCalculationHook;
