import React from "react";
import {
  ComboBox,
  DefaultButton,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import {useSelector} from "react-redux";

import useAddRemoveTagDialogHook from "./hooks/useAddRemoveTagDialogHook";
import type IsaacTagDialogProps from "./AddRemoveTagDialogProps";
import IsaacDialog from "../../../../components/isaacDialog/IsaacDialog";
import IsaacTagDialogType from "./types/IsaacTagDialogType";
import type {RootState} from "../../../../store";

const AddRemoveTagDialog: React.FunctionComponent<IsaacTagDialogProps> = ({
  isHidden,
  type,
  handleDialogDismiss,
}) => {
  const {tags, page} = useSelector((state: RootState) => state.constructions);
  const {isLoading, selectedTags, data, error, handleChange, handleAddDelete} =
    useAddRemoveTagDialogHook(tags, page, type, isHidden, handleDialogDismiss);

  return (
    <IsaacDialog
      isHidden={isHidden}
      dialogContentProps={{
        type: DialogType.normal,
        title:
          type === IsaacTagDialogType.ADD
            ? "Add tag/s to construction"
            : "Remove tag/s from construction",
      }}
      modalProps={{
        titleAriaId: "tag",
        subtitleAriaId: "mySubTextId",
        isBlocking: true,
        containerClassName: "ms-dialogMainOverride ",
      }}
      isLoading={isLoading}
      data={data}
      error={error}
      errorMessage={error}
      handleDialogDismiss={handleDialogDismiss}>
      <>
        <ComboBox
          label={
            type === IsaacTagDialogType.ADD
              ? "Add a new tag or select from existing tags."
              : "Are you sure you want to delete this tag ?"
          }
          allowFreeform={true}
          multiSelect
          selectedKey={selectedTags}
          autoComplete={"off"}
          options={data}
          onChange={handleChange}
        />
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              void (async () => {
                await handleAddDelete();
              })();
              handleDialogDismiss(true);
            }}
            text={type === IsaacTagDialogType.ADD ? "Add" : "Delete"}
            disabled={selectedTags !== undefined && selectedTags.length <= 0}
          />
          <DefaultButton
            onClick={() => {
              handleDialogDismiss(true);
            }}
            text="Cancel"
          />
        </DialogFooter>
      </>
    </IsaacDialog>
  );
};

export default AddRemoveTagDialog;
