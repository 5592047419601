import React from "react";

import styles from "./CalculationConstructionField.module.scss";
import type ICalculationConstructionFieldProps from "./ICalculationConstructionFieldProps";
import {Icon} from "@fluentui/react/lib/Icon";

const CalculationConstructionField: React.FunctionComponent<
  ICalculationConstructionFieldProps
> = ({label, value, highlight, isEditable}) => {
  return (
    <div className={styles.valueBox}>
      <div>{`${label}:`} </div>
      <div className={highlight ? styles.valueYellow : styles.value}>
        {value ?? "-"}
      </div>
      {isEditable && <Icon className={styles.editIcon} iconName="Edit" />}
    </div>
  );
};

export default CalculationConstructionField;
