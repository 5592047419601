export const capitalizeFirstLetter = (value: string | undefined): string => {
  if (value === undefined) {
    return "";
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const toLowerCaseFirstLetter = (value: string | undefined): string => {
  if (value === undefined) {
    return "";
  }
  return value.charAt(0).toLocaleLowerCase() + value.slice(1);
};

export const joinWithoutDuplicates = <T>(arr1: T[], arr2: T[]): T[] => {
  const mergedArray = [...arr1, ...arr2];
  const set = new Set(mergedArray);

  return Array.from(set);
};
